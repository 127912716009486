/* global self, clients, console */
import { registerRoute } from "workbox-routing";
import { CacheFirst } from "workbox-strategies";
import { CacheableResponsePlugin } from 'workbox-cacheable-response';
import { ExpirationPlugin } from "workbox-expiration";
import { RangeRequestsPlugin } from "workbox-range-requests";

self.addEventListener("install", () => {
  self.skipWaiting();
});

self.addEventListener("activate", () => {
  clients.claim().then(() => {
    console.log("The Service Worker has claimed the current page");
  }).catch(error => {
    console.error("Error during clients.claim():", error);
  });
});

const hostedVideoDomainsRegex = /\.(?:amazonaws\.com|cloudfront\.net|walls\.io|wallsio\.com)$/;

const maxAgeSeconds = 3 * 24 * 60 * 60; // 3 days

registerRoute(
  ({ url, request }) => hostedVideoDomainsRegex.test(url.hostname) && request.destination === "video",
  new CacheFirst({
    cacheName: "videos",
    plugins: [
      new CacheableResponsePlugin({
        statuses: [200],
      }),
      new RangeRequestsPlugin(),
      new ExpirationPlugin({
        maxAgeSeconds: maxAgeSeconds
      }),
    ],
  })
);
